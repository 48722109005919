<template>
  <div class="sctp-bg-white">
    <div class="sctp-pad15">
      <el-form class="form" :model="form" :rules="formRules" ref="form" label-width="160px">
        <el-form-item label="已绑定手机号码">
          <div v-if="phone">
            {{ phone }}
            <el-tag size="small" type="success">已认证</el-tag>
          </div>
          <div v-else>
            未认证
          </div>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-input placeholder="请输入验证码" v-model="form.code">
            <el-button @click="sendCode('form')" :disabled="send" slot="append">
              <span v-if="!send">发送验证码</span>
              <count-down v-else @finish="onFinish" format="ss秒后重试" :time="60 * 1000"></count-down>
            </el-button>
          </el-input>
          <div ref="captcha" class="captcha"></div>
        </el-form-item>
        <template v-if="phone">
          <el-form-item label="重新绑定原因" prop="reason">
            <el-select v-model="form.reason" placeholder="请选择">
              <el-option
                v-for="item in reason"
                :key="item.code"
                :label="item.reason"
                :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
        </template>
        <el-form-item>
          <el-button @click="BindPhone('form')" v-if="phone" type="primary" class="sctp-square">
            重新绑定手机号
          </el-button>
          <el-button @click="BindPhone('form')" v-else type="primary" class="sctp-square">绑定手机号
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {captcha} from "@/apis/index";
import validator from '../../../../resources/js/async-validator'

require('@ASSET/libs/jigsaw/jigsaw.min');
export default {
  name: 'bindPhone',
  components: {
    CountDown: () => import('../../../../components/common/CountDown')
  },
  data() {
    return {
      form: {
        phone: null,
        code: null,
        reason: null,
      },
      reason: null,
      phone: null,
      formRules: {
        phone: [
          {required: true, message: '手机号不能为空', trigger: ['blur', 'change']},
          {validator: validator.validPhone, message: '请输入正确手机号!', trigger: ['blur', 'change']},
          {
            validator: (rule, value, callback) => {
              if (this.phone && this.phone === value) {
                callback(new Error('手机号不能与当前绑定手机号一致'));
                return
              }
              this.$request.post({
                reqcode: '1139',
                reqdesc: '绑定手机唯一校验',
                phone: value
              }).then(res => {
                const {data} = res;
                if (data) {
                  callback()
                } else {
                  callback(new Error('手机号已被绑定'))
                }
              }).catch(res => {
                if (res) {
                  callback(new Error('校验异常'))
                }
              })
            }
          }, ['blur', 'change']
        ],
        code: [
          {required: true, message: '验证码不能为空', trigger: 'blur'},
          {len: 6, message: '验证码只能为6位', trigger: 'blur'},
        ],
        reason: [
          {required: true, message: '请选择原因', trigger: 'blur'},
        ]
      },
      send: false // 是否发送验证码
    }
  },
  methods: {
    onFinish() {
      this.send = false
    },
    initCaptcha({onSuccess, onFail}) {
      window.jigsaw.init({
        el: this.$refs.captcha,
        onSuccess: () => {
          this.$refs.captcha.innerHTML = '';
          if (onSuccess) {
            onSuccess();
          }
        },
        onFail: () => {
          if (onFail) {
            onFail();
          }
        },
        onRefresh: () => {
        }
      })
    },
    getPhone() {
      this.$request.post({
        reqcode: '1048',
        reqdesc: '获取用户手机号',
        userId: this.user.userId
      }).then(res => {
        let {data, reason} = res;
        this.phone = data;
        this.reason = reason;
        // this.form.phone = res.data;
      })
    },
    doSendCode() {
      this.send = true;
      captcha.sendPhoneCode({
        phone: this.form.phone
      }).then(res => {
        this.$message.success('验证码发送成功，请注意查收!')
      }).catch(res => {
        this.send = false
      })
    },
    sendCode(formName) {
      this.$refs[formName].validateField('phone', (errMsg) => {
        if (!errMsg) {
          // 发送验证码
          this.initCaptcha({
            onSuccess: this.doSendCode
          });
          return true
        }
        this.$message.warning('请输入正确手机号');
        return false
      })
    },
    bindPhone(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$request.post({
            reqcode: '1005',
            reqdesc: '绑定手机号',
            phone: this.form.phone,
            code: this.form.code,
            userId: this.user.userId,
            reason: this.form.reason
          }).then(res => {
            this.$message.success('绑定成功');
            this.reset(formName)
          })
        }
      })
    },
    reset(formName) {
      this.$refs[formName].resetFields();
      this.send = false;
      this.getPhone()
    },
    unBindPhone(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$request.post({
            reqcode: '1049',
            reqdesc: '解绑手机号',
            phone: this.form.phone,
            code: this.form.code,
            userId: this.user.userId
          }).then(res => {
            this.$message.success('解绑成功');
            this.reset()
          });
          return true
        }
        return false
      })
    }
  },
  mounted() {
  },
  computed: {},
  beforeMount() {
    this.getPhone()
  }
}
</script>

<style scoped>
/*@formatter:off*/
.form {
  width: 500px;
}

.captcha {
  position: absolute !important;
  bottom: 0;
  right: 0;
  background-color: #FFF;
  box-shadow: 0 0 10px 1px #ccc;
  z-index: 1;
}

/*@formatter:on*/
</style>
